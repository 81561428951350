<template>
	<div>
		<b-form-group :label="label">
			<div
				class="input-wrapper d-flex"
				:class="{
					valid: value.$dirty && !value.$invalid,
					invalid: value.$dirty && value.$invalid,
					'validation-enabled': chevron,
					dropdown: tag == 'b-form-select'
				}"
			>
				<component v-if="tag != 'b-form-checkbox'" :is="tag" :type="type" :value="readonly ? value : value.$model" @input="onInput($event)" @change="emitChange" :placeholder="placeholder" locale="sk" :disabled="readonly" :name="name" :options="selectOptions" :class="{ 'invalid-input': value.$dirty && value.$invalid }"></component>
				<!-- CHECKBOX -->
				<component v-else :is="tag" :type="type" @change="onChange($event)" locale="sk" :disabled="readonly" :name="name" :options="selectOptions" :class="{ 'invalid-input': value.$dirty && value.$invalid }" :checked="value.$model" unchecked_value="false"></component>
				<slot></slot>
			</div>
		</b-form-group>

		<!-- Display errors -->
		<div class="text-danger" v-if="value.$dirty && value.$invalid && !readonly">
			<template v-for="param of Object.keys(value.$params)">
				<div v-if="!value[param]" :key="param">
					{{ validationErrors[param] }}
				</div>
			</template>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		value: {
			required: true
		},
		label: {
			required: false,
			default: ''
		},
		name: {
			required: false,
			default: ''
		},
		type: {
			type: String,
			required: false,
			default: 'text'
		},
		tag: {
			type: String,
			required: false,
			default: 'b-form-input'
		},
		placeholder: {
			type: String,
			required: false,
			default: ''
		},
		readonly: {
			type: Boolean,
			required: false,
			default: false
		},
		selectOptions: {
			type: Array,
			required: false,
			default: () => []
		},
		chevron: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data() {
		return {
			validationErrors: {
				required: 'Toto pole je povinné.',
				email: 'Zadaný email nie je v správnom tvare.',
				numeric: 'Hodnota poľa musí byť číslo.',
				minLength: `Toto pole musí obsahovať minimálne ${!this.readonly && this.value.$params.minLength ? this.value.$params.minLength.min : ''} znakov.`,
				sameAsPassword: 'Heslá sa nezhodujú.',
				agreed: 'Pre pokračovanie je potrebný súhlas s podmienkami.'
			}
		}
	},
	methods: {
		onInput(val) {
			this.$set(this.value, '$model', val)
		},
		onChange(val) {
			this.$set(this.value, '$model', val)
		},
		emitChange(val) {
			this.$emit('change', val)
		}
	}
}
</script>
<style lang="sass" scoped>
.input-wrapper
	position: relative

	&.validation-enabled
		.custom-select
			background: #fff

		&::after
			display: flex
			justify-content: center
			align-items: center
			content: ''
			width: 26px
			height: 26px
			position: absolute
			right: 18px
			top: 50%
			transform: translateY(-50%)
			border-radius: 50%
			background-color: #E8E4DA

		&.dropdown::after
			right: 30

		&.valid::after
			background: #E8E4DA url('./img/check-input.svg') center no-repeat

		&.invalid::after
			background: #E8E4DA url('./img/x-input.svg') center no-repeat
</style>
